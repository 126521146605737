<template>
  <div id="ShutterComponents">
    <MyHeader />
    <div class="block1">
      <div class="top">
        <img class="all-img" src="@/assets/shutterComponents/shutterComponents-all.png" />
        <div class="right">
          <h3>Shutter Components</h3>
          <p>We are not only a shutter manufacturer but also a supplier of shutters components,
            we provide a very wide range of options to meet different consumers preferences for
            shutter manufactures through the world. We supply the full range of blade options:
            47 mm/ 63 mm/ 76 mm/ 89 mm/ 114 mm. Custom made profiles are available for special styles.</p>
        </div>
      </div>
      <ul class="components-list">
        <li v-for="(item, index) of componentsList" :key="index">
          <img :src="item.url" />
        </li>
      </ul>
    </div>
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'
export default {
  name: 'ShutterComponents',
  components: { MyHeader, MyFooter },
  data() {
    return {
      componentsList: [
        { url: require('@/assets/shutterComponents/components (11).png') },
        { url: require('@/assets/shutterComponents/components (5).png') },
        { url: require('@/assets/shutterComponents/components (4).png') },
        { url: require('@/assets/shutterComponents/components (13).png') },

        { url: require('@/assets/shutterComponents/components (12).png') },
        { url: require('@/assets/shutterComponents/components (15).png') },
        { url: require('@/assets/shutterComponents/components (17).png') },
        { url: require('@/assets/shutterComponents/components (1).png') },

        { url: require('@/assets/shutterComponents/components (20).png') },
        { url: require('@/assets/shutterComponents/components (18).png') },
        { url: require('@/assets/shutterComponents/components (19).png') },
        { url: require('@/assets/shutterComponents/components (14).png') }

        // { url: require('@/assets/shutterComponents/components (2).png') },
        // { url: require('@/assets/shutterComponents/components (9).png') },
        // { url: require('@/assets/shutterComponents/components (7).png') },
        // { url: require('@/assets/shutterComponents/components (3).png') },
        // { url: require('@/assets/shutterComponents/components (6).png') },
        // { url: require('@/assets/shutterComponents/components (8).png') },
        // { url: require('@/assets/shutterComponents/components (10).png') },
        // { url: require('@/assets/shutterComponents/components (16).png') }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
#ShutterComponents {
  .block1 {
    width: 1440px;
    margin: 0 auto;
    .top {
      display: flex;
      .all-img {
        // width: 1440px;
        // height: 911px;
        // margin-top: 40px;
        width: 815px;
        height: 556px;
      }
      .right {
        width: 718px;
        height: 455px;
        font-family: DINNextLTPro-Medium;
        margin-left: 20px;
        h3 {
          font-size: 42px;
          font-weight: 500;
          line-height: 70px;
          color: #121217;
          text-align: center;
          // font-size: 58px;
          // font-family: DINNextLTPro-Medium;
          // line-height: 93px;
          // color: #3b3b3b;
          // margin: 30px auto 0;
          // text-align: center;
          // font-weight: normal;
        }
        > p {
          // width: 1440px;
          // font-size: 25px;
          // font-family: DINNextLTPro-Regular;
          // line-height: 59px;
          // color: #626262;
          // margin: 0 auto;
          font-family: PingFang SC;
          font-size: 26px;
          font-weight: lighter;
          line-height: 62px;
          color: #626262;
          text-indent: 2em;
        }
      }
    }
    .components-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 90px 0;
      li {
        display: flex;
        margin-bottom: 90px;
        margin-right: 33px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        img {
          width: 335px;
          height: 272px;
        }
      }
    }
  }
}
</style>